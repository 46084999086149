<template>
    <div class="OnboardingInfoBoxWrapper">
        <slot />
    </div>
</template>

<style lang="scss" scoped>
    .OnboardingInfoBoxWrapper {
        background-color: #f1f4f1;
        padding: 16px;
        border-radius: 16px;
    }
</style>
