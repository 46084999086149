<script setup>
    import JetButton from '@jetDS/components/JetButton.vue';
    import JetIcon from '@jetDS/components/JetIcon.vue';

    defineProps({
        showApprovalIcon: {
            type: Boolean,
            required: false,
        },
        showDeleteButton: {
            type: Boolean,
            required: false,
        },
        showSeparator: {
            type: Boolean,
            required: false,
        },
        fileList: {
            type: Array,
            required: true,
        },
    });

    const emit = defineEmits(['file-deleted']);

    function getApprovailIconName(file) {
        return (
            {
                APPROVED: 'check',
                REJECTED: 'times',
            }[file.approval_status] ?? 'ellipsis'
        );
    }
</script>

<template>
    <div class="FileList" :class="{'FileList-with-separator': showSeparator}">
        <div v-if="fileList.length === 0" class="FileList_Empty base light">
            <slot name="empty">Nessun allegato</slot>
        </div>
        <template v-else>
            <transition-group name="slide-up-fade">
                <div v-for="(file, index) in fileList" :key="index" class="FileItem">
                    <a class="FileItem_Link" :href="file.href" target="_blank">
                        <JetIcon name="file" icon-style="regular" class="FileItem_LinkIcon" />
                        <span class="FileItem_TextWrapper">
                            <span class="FileItem_DisplayName">
                                {{ file.display_name }}
                            </span>
                            <span v-if="file.caption" class="FileItem_Caption">
                                {{ file.caption }}
                            </span>
                        </span>
                    </a>
                    <template v-if="showApprovalIcon">
                        <JetIcon
                            class="FileItem_ApprovalIcon"
                            :class="{
                                'FileItem_ApprovalIcon-approved': file.approval_status === 'APPROVED',
                                'FileItem_ApprovalIcon-rejected': file.approval_status === 'REJECTED',
                            }"
                            :name="getApprovailIconName(file)"
                            icon-style="regular" />
                    </template>
                    <JetButton
                        v-if="showDeleteButton"
                        class="FileItem_DeleteButton"
                        icon-name="trash"
                        icon-style="regular"
                        link
                        @click="emit('file-deleted', file)" />
                </div>
            </transition-group>
        </template>
    </div>
</template>

<style lang="scss" scoped>
    .FileList {
        &_Empty {
            color: var(--jet-text-color-placeholder);
            padding: var(--jet-button-padding-vertical-small) 0;
        }
        &-with-separator {
            .FileItem:nth-child(n + 2) {
                border-top: 1px solid var(--jet-border-color-base);
            }
        }
    }
    .FileItem {
        align-items: center;
        display: flex;
        padding: 1px 0;
        &_Link {
            align-items: center;
            display: inline-flex;
            flex: 1 1 auto;
        }
        &_TextWrapper {
            display: inline-block;
            flex: 1 1 auto;
        }
        &_DisplayName {
            display: block;
            color: var(--jet-color-primary-base);
            font-size: var(--jet-font-size-base);
            font-weight: var(--jet-font-weight-regular-medium);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        &_Caption {
            display: block;
            color: var(--jet-text-color-secondary);
            font-size: var(--jet-font-size-xs);
            font-weight: var(--jet-font-weight-regular-medium);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        &_LinkIcon {
            margin-right: 10px;
        }
        &_ApprovalIcon {
            &-approved {
                color: var(--jet-color-success-base);
            }
            &-rejected {
                color: var(--jet-color-danger-base);
            }
        }
        &_DeleteButton {
            margin-left: 10px;
        }
    }

    .slide-up-fade-enter-active {
        transition: all 0.3s ease-out;
    }
    .slide-up-fade-leave-active {
        transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
    }
    .slide-up-fade-enter-from,
    .slide-up-fade-leave-to {
        transform: translateY(100%);
        opacity: 0;
    }
</style>
