<script setup>
    import {ibanRules} from '@jetCommon/helpers/validators.js';

    import JetCallout from '@jetDS/components/JetCallout.vue';
    import JetDivider from '@jetDS/components/JetDivider.vue';
    import JetForm from '@jetDS/components/JetForm.vue';
    import JetFormItem from '@jetDS/components/JetFormItem.vue';
    import JetInput from '@jetDS/components/JetInput.vue';
    import JetRadio from '@jetDS/components/JetRadio.vue';
    import JetRadioGroup from '@jetDS/components/JetRadioGroup.vue';
    import JetSelect from '@jetDS/components/JetSelect.vue';

    const props = defineProps({
        iban: {
            type: String,
            required: true,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        task: {
            type: Object,
            required: true,
        },
    });

    const emit = defineEmits(['submitted']);

    const {$api, $jetMessage} = inject('jet');
    const formRef = ref(null);

    const formData = reactive({
        extra_monthly_pay: props.task.submitted_data?.extra_monthly_pay,
        absences: props.task.submitted_data?.absences,
        should_jet_handle_f24: props.task.submitted_data?.should_jet_handle_f24,
        iban: props.task.submitted_data?.iban || props.iban || null,
    });
    const requiredFieldRule = {required: true, message: 'Campo obbligatorio', trigger: 'blur'};
    const formRules = computed(() => ({
        extra_monthly_pay: [requiredFieldRule],
        absences: [requiredFieldRule],
        should_jet_handle_f24: [requiredFieldRule],
        iban: [...(formData.should_jet_handle_f24 === true ? [requiredFieldRule, ibanRules] : [])],
    }));
    const extraMonthlyPayChoices = ref([]);
    $api.companySettings
        .getExtraMonthlyPayChoices()
        .then(response => {
            extraMonthlyPayChoices.value = response.data;
        })
        .catch($api.end);

    const absencesExpensesChoices = ref([]);
    $api.companySettings
        .getAbsencesExpensesChoices()
        .then(response => {
            absencesExpensesChoices.value = response.data;
        })
        .catch($api.end);

    async function submit() {
        if (props.readOnly) {
            $jetMessage.warning('Non puoi modificare i dati in questo momento.');
            return;
        }

        const formEl = formRef.value.getElFormRef();
        const valid = await formEl.validate().catch(() => false);
        if (valid) {
            $api.companyOnboardingTasks
                .submitData(props.task.id, {
                    ...formData,
                })
                .then(() => emit('submitted'))
                .catch($api.end);
        }
    }

    defineExpose({
        formData,
        submit,
    });
</script>

<template>
    <JetForm ref="formRef" :rules="formRules" :model="formData">
        <JetFormItem
            v-if="extraMonthlyPayChoices.length > 0"
            prop="extra_monthly_pay"
            label="Gestione tredicesima e quattordicesima">
            <JetSelect
                v-model="formData.extra_monthly_pay"
                :disabled="readOnly"
                filterable
                full-width
                placeholder="Seleziona"
                :options="extraMonthlyPayChoices" />
        </JetFormItem>

        <JetFormItem prop="absences" label="Gestione presenze e assenze">
            <JetSelect
                v-if="absencesExpensesChoices.length > 0"
                v-model="formData.absences"
                :disabled="readOnly"
                filterable
                full-width
                placeholder="Gestione presenze e assenze"
                option-height-fluid
                :options="absencesExpensesChoices">
                <template #optionCaption="{option}">
                    {{ option.caption }}
                </template>
            </JetSelect>
        </JetFormItem>

        <JetDivider />

        <p class="ExtraMonthlyPayForm_F24Label">
            Vuoi che gestiamo noi l'F24 da pagare il 16 del mese successivo a ciascun ciclo paghe?
        </p>
        <p class="ExtraMonthlyPayForm_F24Caption xs secondary medium">
            In alternativa potrai continuare a gestirlo tu o il tuo commercialista
        </p>
        <JetFormItem prop="should_jet_handle_f24">
            <JetRadioGroup v-model="formData.should_jet_handle_f24" :disabled="readOnly" vertical>
                <JetRadio :label="true">Sì, gestitelo voi</JetRadio>
                <JetRadio :label="false">No, continuo a gestirlo per conto mio</JetRadio>
            </JetRadioGroup>
        </JetFormItem>

        <JetCallout v-if="formData.should_jet_handle_f24 === false" type="info" plain>
            In questo caso Jet HR produrrà solamente il modello F24 in PDF.
        </JetCallout>

        <template v-if="formData.should_jet_handle_f24 === true">
            <JetFormItem prop="iban" label="IBAN">
                <JetInput v-model="formData.iban" :disabled="readOnly" icon-name="bank" />
            </JetFormItem>
            <JetCallout type="info" plain>
                L'IBAN per l'F24 è diverso da quello che ci hai comunicato per pagare Jet HR? Comunicaci qui quello
                corretto
            </JetCallout>
        </template>

        <JetCallout class="ExtraMonthlyPayForm_WarningCallout" type="warning" plain>
            Una volta salvati questi campi non sarà più possibile modificarli. Se hai fatto un errore contatta
            l'assistenza.
        </JetCallout>
    </JetForm>
</template>

<style lang="scss" scoped>
    .ExtraMonthlyPayForm {
        &_F24Label {
            color: var(--jet-color-primary-base);
            font-size: var(--jet-font-size-base);
            font-weight: var(--jet-font-weight-regular-medium);
            line-height: var(--jet-line-height-xs);
            margin-bottom: 4px;
        }
        &_F24Caption {
            margin-bottom: 4px;
        }
        &_WarningCallout {
            margin-top: 16px;
        }
    }
</style>
