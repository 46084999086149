<script setup>
    import JetInput from '@jetDS/components/JetInput.vue';
    import JetSelect from '@jetDS/components/JetSelect.vue';

    const props = defineProps({
        modelValue: {
            type: [String, null],
            default: null,
        },
        country: {
            type: [String, null],
            default: null,
        },
    });

    const emit = defineEmits(['update:modelValue']);

    const options = ref([]);

    const {$api} = inject('jet');

    async function getOptions() {
        options.value =
            (await $api.locations.getProvinces().catch($api.end)).data?.map(province => ({
                value: province.code,
                display_name: province.value,
            })) ?? [];
    }

    watch(
        () => props.country,
        () => {
            emit('update:modelValue', null);
        },
    );

    getOptions();
</script>

<template>
    <JetSelect
        v-if="country === 'IT'"
        :model-value="modelValue"
        :options="options"
        :disabled="!country"
        filterable
        @update:model-value="newValue => emit('update:modelValue', newValue)" />
    <JetInput
        v-else
        :model-value="modelValue"
        :disabled="!country"
        @update:model-value="newValue => emit('update:modelValue', newValue)" />
</template>
