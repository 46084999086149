<script setup>
    import 'element-plus/es/components/input/style/index';
    import {ElInput} from 'element-plus';

    import JetIcon from '@jetDS/components/JetIcon.vue';

    const props = defineProps({
        iconName: {
            type: String,
            default: null,
        },
        iconStyle: {
            type: String,
            default: 'regular',
        },
        updateValueOnBlur: {
            type: Boolean,
            default: false,
        },
        uppercased: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: [String, Number],
            default: null,
        },
    });

    const emit = defineEmits(['update:modelValue']);

    const internalValue = ref(null);

    watch(
        () => props.modelValue,
        () => {
            internalValue.value = props.modelValue;
        },
        {immediate: true},
    );

    watch(internalValue, () => {
        if (props.uppercased) {
            internalValue.value = internalValue.value.toUpperCase();
        }
        if (props.modelValue !== internalValue.value && !props.updateValueOnBlur) {
            emit('update:modelValue', internalValue.value);
        }
    });

    function onBlur() {
        if (props.updateValueOnBlur) {
            emit('update:modelValue', internalValue.value);
        }
    }

    const iconComponent = computed(() => {
        return props.iconName
            ? h(JetIcon, {
                  name: props.iconName,
                  iconStyle: props.iconStyle,
              })
            : null;
    });
</script>

<template>
    <ElInput
        v-model="internalValue"
        class="jet-input"
        :class="{'jet-input--uppercased': uppercased}"
        :prefix-icon="iconComponent"
        @blur="onBlur">
        <!--
          Expose all slots of the third-party component:
          https://mokkapps.de/vue-tips/expose-slots-from-a-child-component/
        -->
        <template v-for="(_, name) in $slots" #[name]="slotProps">
            <slot :name="name" v-bind="slotProps || {}" />
        </template>
    </ElInput>
</template>

<style scoped lang="scss">
    .jet-input :deep(input),
    .jet-input :deep(textarea) {
        font-family: inherit;
        font-weight: var(--jet-font-weight-regular-medium);
    }

    .jet-input--uppercased :deep(input),
    .jet-input--uppercased :deep(textarea) {
        text-transform: uppercase;
    }

    .jet-input--uppercased :deep(input)::placeholder,
    .jet-input--uppercased :deep(textarea)::placeholder {
        text-transform: none;
    }

    .jet-input.el-textarea {
        &.el-input--large :deep(textarea) {
            padding: var(--jet-input-textarea-padding-vertical-large) var(--jet-input-textarea-padding-horizontal-large);
        }

        &.el-input--default :deep(textarea) {
            padding: var(--jet-input-textarea-padding-vertical-medium)
                var(--jet-input-textarea-padding-horizontal-medium);
        }
    }

    // Default borders override (box-shadow -> proper border)
    .jet-input :deep(.el-input__wrapper),
    .jet-input :deep(.el-input__wrapper:hover),
    .jet-input :deep(.el-input__wrapper:active),
    .jet-input :deep(.el-textarea__inner),
    .jet-input :deep(.el-textarea__inner:hover),
    .jet-input :deep(.el-textarea__inner:active),
    .jet-input :deep(.el-input.is-focus .el-input__wrapper),
    .jet-input :deep(.el-input.is-focus .el-input__wrapper:hover),
    .jet-input :deep(.el-input.is-focus .el-input__wrapper:active),
    .jet-input :deep(.el-input.is-disabled .el-input__wrapper),
    .jet-input :deep(.el-input.is-disabled .el-input__wrapper:hover),
    .jet-input :deep(.el-input.is-disabled .el-input__wrapper:active) {
        box-shadow: none !important;
        border-style: var(--jet-input-border-style);
        border-width: var(--jet-input-border-width);
    }

    .jet-input :deep(.el-input__wrapper),
    .jet-input :deep(.el-input__wrapper:hover),
    .jet-input :deep(.el-textarea__inner),
    .jet-input :deep(.el-textarea__inner:hover) {
        border-color: var(--el-border-color-darker);
    }

    .jet-input :deep(.el-input__wrapper.is-focus),
    .jet-input :deep(.el-textarea__inner:focus) {
        border-color: var(--el-color-primary);
    }

    .jet-input.is-disabled :deep(.el-input__wrapper),
    .jet-input.is-disabled :deep(.el-input__wrapper:hover),
    .jet-input.is-disabled :deep(.el-input__wrapper:active),
    .jet-input.is-disabled :deep(.el-textarea__inner),
    .jet-input.is-disabled :deep(.el-textarea__inner:hover),
    .jet-input.is-disabled :deep(.el-textarea__inner:active) {
        border-color: var(--el-border-color-lighter);
        resize: none;
    }
</style>
