<script setup>
    import 'element-plus/es/components/tooltip/style/index';
    import {ElTooltip} from 'element-plus';

    defineProps({
        placement: {
            type: String,
            default: 'top-start',
        },
        className: {
            type: String,
            default: '',
        },
    });

    const elTooltipRef = ref(null);

    defineExpose({hide: () => elTooltipRef.value.hide()});
</script>

<template>
    <ElTooltip ref="elTooltipRef" effect="dark" :placement="placement" :popper-class="`jet-tooltip ${className}`">
        <template #content>
            <slot name="content" />
        </template>
        <template #default>
            <slot />
        </template>
    </ElTooltip>
</template>

<style lang="scss">
    // The tooltip style could not be scoped because ElTooltip component is teleported,
    // so the scoped style would not affect it
    .jet-tooltip {
        box-sizing: border-box;
        max-width: var(--jet-tooltip-max-width);
        > span:not(.el-popper__arrow) {
            display: inline-block;
            width: 100%;
        }
    }

    .jet-tooltip-no-padding {
        padding: 0;
    }
</style>
