<script setup>
    import {HTTP_400_BAD_REQUEST} from '@jetCommon/constants/http-status.js';
    import {addressFormRules, ssnRules} from '@jetCommon/helpers/validators.js';

    import AddressInput from '@jetCommon/components/inputs/AddressInput.vue';
    import JetForm from '@jetDS/components/JetForm.vue';
    import JetFormItem from '@jetDS/components/JetFormItem.vue';
    import JetInput from '@jetDS/components/JetInput.vue';

    const props = defineProps({
        readOnly: {
            type: Boolean,
            default: false,
        },
        task: {
            type: Object,
            required: true,
        },
    });

    const emit = defineEmits(['submitted']);

    const {$api, $jetMessage} = inject('jet');
    const formRef = ref(null);

    const formData = reactive({
        first_name: props.task.submitted_data?.first_name || null,
        last_name: props.task.submitted_data?.last_name || null,
        ssn: props.task.submitted_data?.ssn || null,
        email: props.task.submitted_data?.email || null,
        address: props.task.submitted_data?.address || {country: 'IT'},
    });

    const requiredFieldRule = {
        required: true,
        message: 'Campo obbligatorio',
        trigger: 'blur',
    };
    const formRules = computed(() => ({
        first_name: [requiredFieldRule],
        last_name: [requiredFieldRule],
        ssn: [requiredFieldRule, ...ssnRules],
        email: [requiredFieldRule],
        address: addressFormRules,
    }));

    const initialErrorData = reactive({
        first_name: null,
        last_name: null,
        ssn: null,
        email: null,
        address: {
            street: null,
            country: null,
            province: null,
            municipality: null,
            postcode: null,
        },
    });
    const errorData = reactive({...initialErrorData});
    function resetErrorData() {
        Object.assign(errorData, initialErrorData);
    }

    async function submit() {
        if (props.readOnly) {
            $jetMessage.warning('Non puoi modificare i dati in questo momento.');
            return;
        }
        resetErrorData();
        const formEl = formRef.value.getElFormRef();
        const valid = await formEl.validate().catch(() => false);
        if (valid) {
            $api.companyOnboardingTasks
                .noMsgForStatusCodes([HTTP_400_BAD_REQUEST])
                .submitData(props.task.id, formData)
                .then(() => emit('submitted'))
                .catch(error => {
                    // representation of server side errors for each form field
                    Object.entries(error.response.data).forEach(entry => {
                        const [key, value] = entry;
                        errorData[key] = value;
                    });
                })
                .catch($api.end);
        }
    }

    defineExpose({
        formData,
        submit,
    });
</script>

<template>
    <JetForm ref="formRef" :rules="formRules" :model="formData">
        <div class="LegalRepresentativeForm_Row">
            <div class="LegalRepresentativeForm_Col">
                <JetFormItem prop="first_name" label="Nome" :error="errorData.first_name">
                    <JetInput v-model="formData.first_name" :disabled="readOnly" />
                </JetFormItem>
            </div>
            <div class="LegalRepresentativeForm_Col">
                <JetFormItem prop="last_name" label="Cognome" :error="errorData.last_name">
                    <JetInput v-model="formData.last_name" :disabled="readOnly" />
                </JetFormItem>
            </div>
        </div>

        <JetFormItem prop="ssn" label="Codice fiscale" :error="errorData.ssn">
            <JetInput v-model="formData.ssn" :disabled="readOnly" maxlength="16" uppercased />
        </JetFormItem>

        <JetFormItem prop="email" label="Email" :error="errorData.email">
            <JetInput v-model="formData.email" :disabled="readOnly" />
        </JetFormItem>

        <AddressInput
            v-model="formData.address"
            :disabled="readOnly"
            :errors="errorData.address"
            props-prefix="address" />
    </JetForm>
</template>

<style lang="scss" scoped>
    .LegalRepresentativeForm {
        &_Row {
            display: flex;
            gap: 16px;
        }

        &_Col {
            flex: 1 1 50%;
        }

        &_Col_80 {
            flex: 1 1 80%;
        }

        &_Col_20 {
            flex: 1 1 20%;
        }
    }
</style>
