<script setup>
    import JetInput from '@jetDS/components/JetInput.vue';
    import JetSelect from '@jetDS/components/JetSelect.vue';

    const props = defineProps({
        modelValue: {
            type: [String, null],
            default: null,
        },
        country: {
            type: [String, null],
            default: null,
        },
        province: {
            type: [String, null],
            default: null,
        },
    });

    const emit = defineEmits(['update:modelValue']);

    const options = ref([]);

    const {$api} = inject('jet');

    async function getOptions(country, province) {
        if (country !== 'IT') {
            options.value = [];
        } else if (province) {
            options.value =
                (
                    await $api.locations
                        .getMunicipalities({
                            province_code: province,
                        })
                        .catch($api.end)
                ).data?.map(result => ({
                    value: result.code,
                    display_name: result.value,
                })) ?? [];
        } else {
            options.value = [];
        }
    }

    watch(
        () => props.province,
        () => {
            emit('update:modelValue', null);
        },
    );

    watch(
        () => [props.country, props.province],
        ([newCountry, newProvince]) => {
            getOptions(newCountry, newProvince);
            emit('update:modelValue', null);
        },
    );

    getOptions(props.country, props.province);
</script>

<template>
    <JetSelect
        v-if="country === 'IT'"
        :model-value="modelValue"
        :options="options"
        :disabled="!province"
        filterable
        @update:model-value="newValue => emit('update:modelValue', newValue)" />
    <JetInput
        v-else
        :model-value="modelValue"
        :disabled="!province"
        @update:model-value="newValue => emit('update:modelValue', newValue)" />
</template>
