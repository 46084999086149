<script setup>
    /* eslint-disable jethr/imports-order */
    import {api} from '@/api';
    import {useSpaStore} from '@/stores/spa.js';
    import ImpersonationSession from '@jetCommon/components/ImpersonationSession.vue';
    import {initJetTools} from '@jetCommon/jet-tools.js';
    import it from '@jetCommon/locale.js';
    import settings from '@/env.js';

    import {ElConfigProvider} from 'element-plus';
    import 'element-plus/es/components/config-provider/style/index';

    const spaStore = useSpaStore();

    const jetTools = initJetTools({
        api,
        routeEntityName: settings.JET_ROUTE_ENTITY_NAME,
        enableAuthStore: true,
        spaStore,
    });
    provide('jet', jetTools);

    import {storeToRefs} from 'pinia';
    const {hasJetRole, isImpersonationSession, person} = storeToRefs(jetTools.$authStore);
    const {company} = storeToRefs(jetTools.$spaStore);

    import installIntercomWidget from '@jetCommon/integrations/intercom.js';

    watch(company, (currentCompanyValue, previousCompanyValue) => {
        const personValue = person.value;
        if (
            previousCompanyValue === null &&
            currentCompanyValue &&
            personValue &&
            personValue.real_person_id === null &&
            !hasJetRole.value
        ) {
            api.companies
                .getIntercomConfiguration(currentCompanyValue.id)
                .then(response => {
                    const {app_id, user_hash} = response.data;
                    installIntercomWidget(app_id, {
                        user_id: `${personValue.id}`,
                        user_hash,
                        email: personValue.personal_email,
                        name: personValue.full_name,
                        company: {
                            company_id: `${currentCompanyValue.id}`,
                            name: currentCompanyValue.name,
                        },
                    });
                })
                .catch(api.end);
        }
    });
</script>

<template>
    <ElConfigProvider :locale="it">
        <ImpersonationSession
            v-if="isImpersonationSession"
            :initial-remaining-seconds="person.impersonation_remaining_seconds" />
        <router-view />
    </ElConfigProvider>
</template>

<style lang="scss">
    body {
        border: 0;
        padding: 0;
        margin: 0;
    }
</style>
