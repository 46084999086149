<script setup>
    import {ONBOARDING_TASK_CODENAME, ONBOARDING_TASK_STATUS} from '@jetCommon/constants/onboarding.js';
    import {isoDateToIta} from '@jetCommon/helpers/date.js';
    import {useOnboarding} from '@/features/onboarding/composables/onboarding.js';

    import JetDatePicker from '@jetDS/components/JetDatePicker.vue';
    import JetForm from '@jetDS/components/JetForm.vue';
    import JetFormItem from '@jetDS/components/JetFormItem.vue';
    import OnboardingTaskItem from '@/features/onboarding/components/tasks/base/OnboardingTaskItem.vue';

    const {$api} = inject('jet');
    const {fetchAll, taskByCodename} = useOnboarding();
    const task = computed(() => taskByCodename.value[ONBOARDING_TASK_CODENAME.CSO_730_MANDATE]);

    const formRef = ref(null);
    const formData = reactive({
        mandate_date: null,
    });

    const formRules = reactive({
        mandate_date: [{required: true, message: 'Campo obbligatorio', trigger: 'blur'}],
    });

    const metaText = computed(() => {
        return task.value.status === ONBOARDING_TASK_STATUS.DONE && task.value?.submitted_data?.mandate_date
            ? isoDateToIta(task.value.submitted_data.mandate_date)
            : null;
    });

    const actions = computed(() => {
        const compileForm = {
            main: true,
            text: 'Comunica',
            dialog: {
                title: 'Delega modello CSO (730)',
                name: 'main',
                confirm_button_text: 'Comunica data',
                async confirm_callback() {
                    const valid = await formRef.value
                        .getElFormRef()
                        .validate()
                        .catch(() => false);
                    if (valid) {
                        $api.companyOnboardingTasks.submitData(task.value.id, formData).then(fetchAll).catch($api.end);
                    }
                },
            },
        };

        return task.value.status === ONBOARDING_TASK_STATUS.TODO ? [compileForm] : [];
    });
</script>

<template>
    <OnboardingTaskItem
        :actions="actions"
        class="onboardingtaskitem__cso730mandate"
        :codename="ONBOARDING_TASK_CODENAME.CSO_730_MANDATE"
        description="Comunicaci la data in cui hai delegato la gestione al consulente di Jet HR."
        :meta-text="metaText"
        title="Delega modello CSO (730)">
        <template #dialogMainBody>
            <p class="cso730mandate__paragraph">
                Nei mesi che vanno da Maggio a Luglio i tuoi dipendenti dovranno preparare la dichiarazione dei redditi
                (modello 730/2024).
            </p>
            <p class="cso730mandate__paragraph">
                Delega il tuo Consulente del lavoro del Jet HR Partner Network sul sito dell'Agenzia delle Entrate.
                Potrà accedere in autonomia alla tua area personale e scaricarsi tutte le informazioni che gli servono
                per gestire questi adempimenti al posto tuo.
            </p>
            <p class="cso730mandate__paragraph">
                <a
                    href="https://help.jethr.com/it/articles/9204096-come-delegare-il-consulente-del-lavoro-davanti-all-agenzia-delle-entrate"
                    target="_blank">
                    Premi qui per vedere la guida su come farlo
                </a>
            </p>

            <JetForm ref="formRef" :rules="formRules" :model="formData">
                <JetFormItem prop="mandate_date" label="Una volta fatto comunicaci la data">
                    <JetDatePicker v-model="formData.mandate_date" full-width />
                </JetFormItem>
            </JetForm>
        </template>
    </OnboardingTaskItem>
</template>

<style lang="scss" scoped>
    .cso730mandate__paragraph {
        margin-bottom: 12px;
    }
</style>
