import {ONBOARDING_TASK_CODENAME, ONBOARDING_TASK_STATUS} from '@jetCommon/constants/onboarding.js';
import {addMonths, parseISO, startOfDay} from 'date-fns';
import {api} from '@/api';
import {capMonthName, isoDateToItaVerbose, parseDatetime} from '@jetCommon/helpers/date.js';
import {computed, ref, watch} from 'vue';
import {storeToRefs} from 'pinia';
import {useSpaStore} from '@/stores/spa.js';

import OnboardingTaskAntiMoneyLaundering from '@/features/onboarding/components/tasks/OnboardingTaskAntiMoneyLaundering.vue';
import OnboardingTaskCompanyRegistrationReport from '@/features/onboarding/components/tasks/OnboardingTaskCompanyRegistrationReport.vue';
import OnboardingTaskConsultantCancellationDate from '@/features/onboarding/components/tasks/OnboardingTaskConsultantCancellationDate.vue';
import OnboardingTaskCso730Mandate from '@/features/onboarding/components/tasks/OnboardingTaskCso730Mandate.vue';
import OnboardingTaskDocumentCollection from '@/features/onboarding/components/tasks/OnboardingTaskDocumentCollection.vue';
import OnboardingTaskExtraMonthlyPay from '@/features/onboarding/components/tasks/OnboardingTaskExtraMonthlyPay.vue';
import OnboardingTaskF24Mandate from '@/features/onboarding/components/tasks/OnboardingTaskF24Mandate.vue';
import OnboardingTaskIban from '@/features/onboarding/components/tasks/OnboardingTaskIban.vue';
import OnboardingTaskLegalRepresentative from '@/features/onboarding/components/tasks/OnboardingTaskLegalRepresentative.vue';
import OnboardingTaskLulF24Prospects from '@/features/onboarding/components/tasks/OnboardingTaskLulF24Prospects.vue';
import OnboardingTaskMaintainLulPecSendingDate from '@/features/onboarding/components/tasks/OnboardingTaskMaintainLulPecSendingDate.vue';
import OnboardingTaskMandates from '@/features/onboarding/components/tasks/OnboardingTaskMandates.vue';
import OnboardingTaskPayrollDates from '@/features/onboarding/components/tasks/OnboardingTaskPayrollDates.vue';
import OnboardingTaskPreviousLul from '@/features/onboarding/components/tasks/OnboardingTaskPreviousLul.vue';
import OnboardingTaskWorkforceData from '@/features/onboarding/components/tasks/OnboardingTaskWorkforceData.vue';

// codenames ordering is used for rendering
const STEPS_CONFIG = [
    {
        codenames: [
            ONBOARDING_TASK_CODENAME.COMPANY_REGISTRATION_REPORT,
            ONBOARDING_TASK_CODENAME.LEGAL_REPRESENTATIVE,
            ONBOARDING_TASK_CODENAME.WORKFORCE_DATA,
            ONBOARDING_TASK_CODENAME.LUL_F24_PROSPECTS,
            ONBOARDING_TASK_CODENAME.PAYROLL_DATES,
            ONBOARDING_TASK_CODENAME.EXTRA_MONTHLY_PAY,
            ONBOARDING_TASK_CODENAME.IBAN,
            ONBOARDING_TASK_CODENAME.CONSULTANT_CANCELLATION_DATE,
        ],
        due_date_field: 'activation_step_due_date',
        title: "Step necessari per attivare l'account",
    },
    {
        codenames: [
            ONBOARDING_TASK_CODENAME.DOCUMENT_COLLECTION,
            ONBOARDING_TASK_CODENAME.ANTI_MONEY_LAUNDERING,
            ONBOARDING_TASK_CODENAME.MANDATES,
            ONBOARDING_TASK_CODENAME.CSO_730_MANDATE,
            ONBOARDING_TASK_CODENAME.F24_MANDATE,
            ONBOARDING_TASK_CODENAME.MAINTAIN_LUL_PEC_SENDING_DATE,
            ONBOARDING_TASK_CODENAME.PREVIOUS_LUL,
        ],
        due_date_field: 'payroll_step_due_date',
        title: 'Step necessari per pagare gli stipendi',
    },
];

const COMPONENTS_BY_CODENAME = {
    [ONBOARDING_TASK_CODENAME.ANTI_MONEY_LAUNDERING]: OnboardingTaskAntiMoneyLaundering,
    [ONBOARDING_TASK_CODENAME.COMPANY_REGISTRATION_REPORT]: OnboardingTaskCompanyRegistrationReport,
    [ONBOARDING_TASK_CODENAME.CONSULTANT_CANCELLATION_DATE]: OnboardingTaskConsultantCancellationDate,
    [ONBOARDING_TASK_CODENAME.CSO_730_MANDATE]: OnboardingTaskCso730Mandate,
    [ONBOARDING_TASK_CODENAME.DOCUMENT_COLLECTION]: OnboardingTaskDocumentCollection,
    [ONBOARDING_TASK_CODENAME.EXTRA_MONTHLY_PAY]: OnboardingTaskExtraMonthlyPay,
    [ONBOARDING_TASK_CODENAME.IBAN]: OnboardingTaskIban,
    [ONBOARDING_TASK_CODENAME.F24_MANDATE]: OnboardingTaskF24Mandate,
    [ONBOARDING_TASK_CODENAME.LEGAL_REPRESENTATIVE]: OnboardingTaskLegalRepresentative,
    [ONBOARDING_TASK_CODENAME.LUL_F24_PROSPECTS]: OnboardingTaskLulF24Prospects,
    [ONBOARDING_TASK_CODENAME.MAINTAIN_LUL_PEC_SENDING_DATE]: OnboardingTaskMaintainLulPecSendingDate,
    [ONBOARDING_TASK_CODENAME.MANDATES]: OnboardingTaskMandates,
    [ONBOARDING_TASK_CODENAME.PAYROLL_DATES]: OnboardingTaskPayrollDates,
    [ONBOARDING_TASK_CODENAME.PREVIOUS_LUL]: OnboardingTaskPreviousLul,
    [ONBOARDING_TASK_CODENAME.WORKFORCE_DATA]: OnboardingTaskWorkforceData,
};

const remoteData = ref(null);
const onboardingIsActive = ref(null);
const accountIsFullyActivated = ref(null);

const isReady = computed(() => {
    return remoteData.value !== null;
});

const taskByCodename = computed(() => {
    return (remoteData.value?.tasks || []).reduce((acc, task) => ({...acc, [task.codename]: task}), {});
});

const firstPayrollMonthName = computed(() => {
    return isReady.value ? isoDateToItaVerbose(remoteData.value.company_first_payroll_date, {month: 'long'}) : '';
});

const steps = computed(() => {
    if (!isReady.value) {
        return null;
    }
    const _steps = STEPS_CONFIG.map(stepConfig => {
        const stepActiveTasks = stepConfig.codenames
            .map(codename => taskByCodename.value[codename])
            .filter(task => Boolean(task));
        const codenames = stepActiveTasks.map(task => task.codename);
        return {
            ...stepConfig,
            codenames,
            todo_count: stepActiveTasks.filter(task => task.status === ONBOARDING_TASK_STATUS.TODO).length,
            due_date: parseDatetime(remoteData.value[stepConfig.due_date_field]),
            due_date_str: isoDateToItaVerbose(remoteData.value[stepConfig.due_date_field], {
                day: 'numeric',
                month: 'long',
            }),
            components: codenames.map(codename => COMPONENTS_BY_CODENAME[codename]),
        };
    });
    _steps.map((step, index) => {
        const prevSteps = _steps.slice(0, index);
        step.initial_opened =
            // Previous steps are all done
            prevSteps.every(prevStep => prevStep.todo_count === 0) &&
            // Current step has tasks to do
            step.todo_count > 0;
        return step;
    });
    return _steps;
});

const totalToDoCount = computed(() => {
    return (remoteData.value?.tasks || []).filter(task => task.status === ONBOARDING_TASK_STATUS.TODO).length;
});

const isInTime = computed(() => {
    const today = startOfDay(new Date());
    return steps.value.filter(step => step.due_date < today).every(step => step.todo_count === 0);
});

export function useOnboarding() {
    const spaStore = useSpaStore();
    const {company, companyId} = storeToRefs(spaStore);

    watch(
        company,
        () => {
            onboardingIsActive.value =
                company.value?.onboarding && // Onboarding is managed in Jet
                company.value?.activation_status === 'ONBOARDING';
            accountIsFullyActivated.value = Boolean(company.value?.accounts_activated_at);
        },
        {immediate: true},
    );

    function fetchAll() {
        api.companies
            .getOnboarding(companyId.value, {expand_tasks: true})
            .then(response => {
                remoteData.value = response.data;
            })
            .catch(api.end);
    }

    const previousLulMonthStr = computed(() => {
        const refDate = addMonths(parseISO(company.value?.first_payroll_date), -1);
        return `${capMonthName(refDate.getMonth() + 1)} ${refDate.getFullYear()}`;
    });

    return {
        accountIsFullyActivated,
        company,
        companyId,
        fetchAll,
        firstPayrollMonthName,
        isInTime,
        isReady,
        onboardingIsActive,
        previousLulMonthStr,
        remoteData,
        steps,
        taskByCodename,
        totalToDoCount,
    };
}
